 // * Usage:
 // * ------
 // * .selector {
 // *      width:100%;
 // *
 // *      @include breakpoint(medium) {
 // *          width:90%;
 // *      }
 // *
 // *      @include breakpoint(large) {
 // *          width:960px;
 // *      }
 // * }
 // *
 // * Breakpoint min-widths are defined in the $breakpoints map (look in _config.scss)


@mixin breakpoint($point, $direction: min) {
    @if map-has-key($breakpoints, $point) {
        @media ( #{$direction+'-width'}:
            if( $direction == min, map-get($breakpoints, $point), map-get($breakpoints, $point) - em(1px))
        ) { @content; }
    }
    @else {
        @error "The breakpoint #{$point} is not associated with a min/max-width.";
    }
}