@include breakpoint(giant) {


  // Animations variables
  $aos-distance: 100px !default;

  @media screen {
    body:not(.nojs) {
      /**
      * Fade animations:
      * fade
      * fade-up, fade-down, fade-left, fade-right
      * fade-up-right, fade-up-left, fade-down-right, fade-down-left
      */

      [data-aos^='fade'][data-aos^='fade'] {
        opacity: 0;
        transition-property: opacity, transform;

        &.aos-animate {
          opacity: 1;
          transform: none;
        }
      }

      [data-aos='fade-up'] {
        transform: translate3d(0, $aos-distance, 0);
      }

      [data-aos='fade-down'] {
        transform: translate3d(0, -$aos-distance, 0);
      }

      [data-aos='fade-right'] {
        transform: translate3d(-$aos-distance, 0, 0);
      }

      [data-aos='fade-left'] {
        transform: translate3d($aos-distance, 0, 0);
      }

      [data-aos='fade-up-right'] {
        transform: translate3d(-$aos-distance, $aos-distance, 0);
      }

      [data-aos='fade-up-left'] {
        transform: translate3d($aos-distance, $aos-distance, 0);
      }

      [data-aos='fade-down-right'] {
        transform: translate3d(-$aos-distance, -$aos-distance, 0);
      }

      [data-aos='fade-down-left'] {
        transform: translate3d($aos-distance, -$aos-distance, 0);
      }




      /**
      * Zoom animations:
      * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
      * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
      */

      [data-aos^='zoom'][data-aos^='zoom'] {
        opacity: 0;
        transition-property: opacity, transform;

        &.aos-animate {
          opacity: 1;
          transform: translate3d(0, 0, 0) scale(1);
        }
      }

      [data-aos='zoom-in'] {
        transform: scale(.6);
      }

      [data-aos='zoom-in-up'] {
        transform: translate3d(0, $aos-distance, 0) scale(.6);
      }

      [data-aos='zoom-in-down'] {
        transform: translate3d(0, -$aos-distance, 0) scale(.6);
      }

      [data-aos='zoom-in-right'] {
        transform: translate3d(-$aos-distance, 0, 0) scale(.6);
      }

      [data-aos='zoom-in-left'] {
        transform: translate3d($aos-distance, 0, 0) scale(.6);
      }

      [data-aos='zoom-out'] {
        transform: scale(1.2);
      }

      [data-aos='zoom-out-up'] {
        transform: translate3d(0, $aos-distance, 0) scale(1.2);
      }

      [data-aos='zoom-out-down'] {
        transform: translate3d(0, -$aos-distance, 0) scale(1.2);
      }

      [data-aos='zoom-out-right'] {
        transform: translate3d(-$aos-distance, 0, 0) scale(1.2);
      }

      [data-aos='zoom-out-left'] {
        transform: translate3d($aos-distance, 0, 0) scale(1.2);
      }




      /**
      * Slide animations
      */

      [data-aos^='slide'][data-aos^='slide'] {
        transition-property: transform;
        visibility: hidden;

        &.aos-animate {
          visibility: visible;
          transform: translate3d(0, 0, 0);
        }
      }

      [data-aos='slide-up'] {
        transform: translate3d(0, 100%, 0);
      }

      [data-aos='slide-down'] {
        transform: translate3d(0, -100%, 0);
      }

      [data-aos='slide-right'] {
        transform: translate3d(-100%, 0, 0);
      }

      [data-aos='slide-left'] {
        transform: translate3d(100%, 0, 0);
      }




      /**
      * Flip animations:
      * flip-left, flip-right, flip-up, flip-down
      */

      [data-aos^='flip'][data-aos^='flip'] {
        backface-visibility: hidden;
        transition-property: transform;
      }

      [data-aos='flip-left'] {
        transform: perspective(2500px) rotateY(-100deg);
        &.aos-animate {transform: perspective(2500px) rotateY(0);}
      }

      [data-aos='flip-right'] {
        transform: perspective(2500px) rotateY(100deg);
        &.aos-animate {transform: perspective(2500px) rotateY(0);}
      }

      [data-aos='flip-up'] {
        transform: perspective(2500px) rotateX(-100deg);
        &.aos-animate {transform: perspective(2500px) rotateX(0);}
      }

      [data-aos='flip-down'] {
        transform: perspective(2500px) rotateX(100deg);
        &.aos-animate {transform: perspective(2500px) rotateX(0);}
      }
    }
  }
}