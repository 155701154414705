// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

// @include font-face(
//  $name: "icomoon",
//  $file: "icomoon-webfont",
//  $weight: 400,
//  $style: normal,
//  $type: woff
// );

@include font-face(
    $name: "FontAwesome",
    $file: "fa-regular-400",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "FontAwesome",
    $file: "fa-solid-900",
    $weight: 900,
    $style: normal,
    $type: woff
);

@import url("https://p.typekit.net/p.css?s=1&k=joz6jxo&ht=tk&f=28423.28431.28434.28435.28443.28447.28448.28449.28450.28452.28457.28462.28464.28474.28475.28477&a=6223579&app=typekit&e=css");

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-style:normal;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-style:normal;font-weight:700;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/bd2066/00000000000000003b9adbdb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/bd2066/00000000000000003b9adbdb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/bd2066/00000000000000003b9adbdb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-style:italic;font-weight:700;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/220525/00000000000000003b9adbdc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/220525/00000000000000003b9adbdc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/220525/00000000000000003b9adbdc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-style:italic;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-modern-narrow";
src:url("https://use.typekit.net/af/1fab22/00000000000000003b9adbe4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/1fab22/00000000000000003b9adbe4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/1fab22/00000000000000003b9adbe4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-style:normal;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-modern-narrow";
src:url("https://use.typekit.net/af/9804e9/00000000000000003b9adbe8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/9804e9/00000000000000003b9adbe8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/9804e9/00000000000000003b9adbe8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-style:italic;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-modern-narrow";
src:url("https://use.typekit.net/af/350e8c/00000000000000003b9adbe9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/350e8c/00000000000000003b9adbe9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/350e8c/00000000000000003b9adbe9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-style:normal;font-weight:700;
}

@font-face {
font-family:"mr-eaves-xl-modern-narrow";
src:url("https://use.typekit.net/af/c02ed9/00000000000000003b9adbea/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/c02ed9/00000000000000003b9adbea/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/c02ed9/00000000000000003b9adbea/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-style:italic;font-weight:700;
}

@font-face {
font-family:"mr-eaves-xl-sans";
src:url("https://use.typekit.net/af/1801b7/00000000000000003b9adbeb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/1801b7/00000000000000003b9adbeb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/1801b7/00000000000000003b9adbeb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-style:normal;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-sans";
src:url("https://use.typekit.net/af/bbced3/00000000000000003b9adbed/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/bbced3/00000000000000003b9adbed/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/bbced3/00000000000000003b9adbed/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-style:normal;font-weight:700;
}

@font-face {
font-family:"mr-eaves-xl-sans";
src:url("https://use.typekit.net/af/163684/00000000000000003b9adbf2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/163684/00000000000000003b9adbf2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/163684/00000000000000003b9adbf2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-style:italic;font-weight:700;
}

@font-face {
font-family:"mr-eaves-xl-sans";
src:url("https://use.typekit.net/af/3d9923/00000000000000003b9adbf7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/3d9923/00000000000000003b9adbf7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/3d9923/00000000000000003b9adbf7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-style:italic;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-sans-narrow";
src:url("https://use.typekit.net/af/639731/00000000000000003b9adbf9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/639731/00000000000000003b9adbf9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/639731/00000000000000003b9adbf9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-style:italic;font-weight:700;
}

@font-face {
font-family:"mr-eaves-xl-sans-narrow";
src:url("https://use.typekit.net/af/039712/00000000000000003b9adc03/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/039712/00000000000000003b9adc03/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/039712/00000000000000003b9adc03/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-style:normal;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-sans-narrow";
src:url("https://use.typekit.net/af/5c13ef/00000000000000003b9adc04/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/5c13ef/00000000000000003b9adc04/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/5c13ef/00000000000000003b9adc04/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-style:italic;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-sans-narrow";
src:url("https://use.typekit.net/af/2d5340/00000000000000003b9adc06/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2d5340/00000000000000003b9adc06/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2d5340/00000000000000003b9adc06/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-style:normal;font-weight:700;
}

.tk-mr-eaves-xl-modern { font-family: "mr-eaves-xl-modern",sans-serif; }
.tk-mr-eaves-xl-modern-narrow { font-family: "mr-eaves-xl-modern-narrow",sans-serif; }
.tk-mr-eaves-xl-sans { font-family: "mr-eaves-xl-sans",sans-serif; }
.tk-mr-eaves-xl-sans-narrow { font-family: "mr-eaves-xl-sans-narrow",sans-serif; }

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?ar0aw7');
    src:  url('fonts/icomoon.eot?ar0aw7#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?ar0aw7') format('truetype'),
      url('fonts/icomoon.woff?ar0aw7') format('woff'),
      url('fonts/icomoon.svg?ar0aw7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-home:before {
    content: "\e905";
  }
  .icon-pencil2:before {
    content: "\e906";
  }
  .icon-search:before {
    content: "\e986";
  }
  .icon-caret-right:before {
    content: "\e902";
  }
  .icon-caret-up:before {
    content: "\e903";
  }
  .icon-exclamation:before {
    content: "\e90d";
  }
  .icon-minus:before {
    content: "\e90c";
  }
  .icon-plus:before {
    content: "\e90a";
  }
  .icon-times:before {
    content: "\e909";
  }
  .icon-paperplane:before {
    content: "\e904";
  }
  .icon-phone:before {
    content: "\e900";
  }
  .icon-arrow-down:before {
    content: "\e901";
  }
  