.wp-block-image {
    > figure {
        &.alignleft {
            float: left;
            margin-right: rem($base-gap);
        }

        &.alignright {
            float: right;
            margin-left: rem($base-gap);
        }

        &.aligncenter {
            display: table;
            margin: 0 auto;
        }
    }

    + p, + ul, + ol, + dl, + table {
        @include pie-clearfix
    }
}
.marginBottom {
	margin-bottom: rem($base-line-height);
}
body.blog main figure {
    &.alignleft {
        float: left;
        margin-right: rem($base-gap);
    }

    &.alignright {
        float: right;
        margin-left: rem($base-gap);
    }

    &.aligncenter {
        display: table;
        margin: 0 auto;
    }
}

.lh13 {
    line-height: 1.3;
}

.highlight {
    color: $secondary;
}

.btn, .wp-block-button__link {
    background: $primary;
    border: none;
    color: $light;
    cursor: pointer;
    display: table;
    font-size: 1rem;
    line-height: 1rem;
    padding: 1.5rem 1rem;
    text-align: center;
    font-weight:300;
    font-family:$display-font;
    text-decoration: none;
    transition: 300ms all ease-in-out;
    border-radius: 6px;

    &:hover, &:active, &:focus {
        background: $green;
        color: $light;
    }

    *[class^="icon"] {
        margin-left: .5rem;
        vertical-align: middle;
    }
      &:after {
          content: "\f105";
            font-family: $icon-font;
            display: inline-block;
            margin-left: .5rem;
            font-weight: 900;
            font-size: 13px;
    }

    &.isHollow {
        background-color: transparent;
        border: 1px solid rgba($dark, 0.25);
        color: $dark;
        padding: 0.5rem 1rem;

        &:hover, &:active, &:focus {
            background-color: mix($light, $dark, 92.5%);
        }
    }

    &.block{
        display: flex;
        justify-content: center;
        margin: 10px;
        margin-bottom: 23px;
        &.categorieBtn {
            margin-bottom: 32px;   
        }
    }
    &.big {
        font-size: 18px;

        @include breakpoint (small) {
            font-size: 20px;
        }
	}
    &.categorieBtn {
        padding: 1rem 1rem; 
    }
}

.wp-block-button__link {
    &:after {
        display: none;
    }
}

.wp-block-buttons {
    display: flex;
    flex-wrap: wrap;

    > .wp-block-button {
        &:not(:last-child) {
            margin-right: rem($base-gap);
        }
    }
}

.clear {
    clear: both;
    float: none;
}

.clearfix {
    @include pie-clearfix;
}

.hidden {
    display: none;
}

.hide-text {
    @include hide-text();
    display: inline-block;
    overflow: hidden;
    width: 0;
}

.noSpace {
    p {
        @include breakpoint(large,max) {
            margin-bottom: 0;
        }
    }
}
.inherit {
    display: inherit;
}

.float-left {
    float: left;
}

img.float-left {
    margin-right: 1rem;
}

.float-right {
    float: right;
}

img.float-right {
    margin-left: 1rem;
}

.fluid {
    height: auto;
    width: 100%;
}

.non-fluid {
    width: auto!important;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.wide {
    width: 100%;
    display: block;
}

.notification {
    padding: 1em;
    background: $alert;
    color: $light;
    font-size: 1.2em;
}

/* 16:9 video resolutions */
.video-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
        border: none;
        height: 100%;
        position: absolute;
        width: 100%;
    }
}

.lightbox-inline {

}

.lightbox-external {

}

/* default colorbox hover */
.lightbox-image {
    @extend .margin-bottom;
    display: table;
    position: relative;
    text-decoration: none;
    width: 100%;

    &[href*=qr] {
        width: auto;

        &:before,
        &:after {
            content:none;
        }
    }

    &:before, &:after {
        @extend %animated-transform;
        pointer-events: none;
    }

    &:before {
        bottom: 0;
        color: rgba($light, .75);
        content: '+';
        display: block;
        font-family: sans-serif;
        font-size: 2rem;
        height: 2em;
        line-height: 1.8;
        position: absolute;
        right: 0;
        text-align: center;
        text-shadow: rem(1px) rem(1px) rem(1px) rgba($dark, .8);
        transform: translate3d(0, 0, 0);
        width: 2em;
        z-index: 1;
    }

    &:after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    &:hover, &:focus {

        &:before {
            bottom: 50%;
            font-size: 3rem;
            height: 1.5em;
            line-height: 1.5;
            right: 50%;
            transform: translate3d(50%, 50%, 0);
            width: 1.5em;
        }

        &:after {
            opacity: .5;
        }
    }
}

body {
    &:before {
        $content: 'default:' + $bp-context;
        width: str_length($content) * 6pt + $base-gap * 2;

        @each $point, $width in $breakpoints {
            $content: $content + '....' + $point + ':' + $width;

            @include breakpoint($point) {
                width: str_length($content) * 6pt + $base-gap * 2;
            }
        }

        content: '#{$content}';
        display: none !important; /* Prevent from displaying. */
    }

    &.devmode:before, &.debug:before {
        background: $alert;
        border-color: $alert;
        border-style: solid;
        border-width: 0 rem($base-gap);
        color: $light;
        display: block !important;
        font-family: Courier;
        font-size: 10pt;
        left: 0;
        line-height: 2.5;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        white-space: nowrap;
        z-index: 99999;
    }
}

// Animations
%animated-transform {
    transition: .3s;
}

.strong {
    font-weight:bold;
}

.height {
    height: 703px;
}

.description {
    color: $primary;
    font-size: 90%;
    font-style: italic;
    margin-bottom: rem(20px);
    margin-top: rem(-25px);

    @include breakpoint(tiny) {
        margin-top: rem(-75px);
    }

    @include breakpoint(medium) {
        margin-top: rem(-25px);
    }

    @include breakpoint(large) {
        margin-top: rem(-60px);
    }

    @include breakpoint(giant) {
        margin-top: rem(-85px);
    }

    @include breakpoint(huge) {
        margin-top: rem(-95px);
    }

}

p .headline, .noMargin {
    margin-bottom: 0;
    margin-top: rem(-10px);

     @include breakpoint(tiny) {
        margin-top: rem(-35px);
    }

    @include breakpoint(medium) {
        line-height: rem(20px);
    }
}


h2.smaller {
    margin-top: rem(-45px);
    font-size: rem(25px)!important;

    @include breakpoint(medium) {
        line-height: rem(20px);
    }

    @include breakpoint(huge) {
    font-size: rem(30px)!important;
    }
}

h2.single {

    @include breakpoint(medium) {
        margin-top: rem(-75px);
    }
}

p.long {
    @include breakpoint(medium) {
    margin-top: rem(-33px);
    margin-bottom: rem(40px);
    }

    @include breakpoint(huge) {
        margin-top: rem(-65px);
    }
}


