// PAGE WRAP
//////////////////////////////

.page-wrap {
    padding-top: 0rem;
    overflow: hidden;

    @include breakpoint(medium) {
        padding-top: 0.67778rem;
    }

    @include breakpoint(large) {
        padding-top: 0;
    }
}
// POPUP
//////////////////////////////




.popUpContainer {
    body:not(.cmsbackend) & {
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        overflow: hidden;
        transition: opacity .3s, visibility .3s;
        position: fixed;
        visibility: visible;
        opacity: 1;
        z-index: 1000000;

        .popUpContent {
            background: url(../images/html-karton-bg.jpg);
            background-size: auto;
            width: 100%;
            max-height: 90vh;
            max-width: calc(100% - #{rem(110px)}); // for the close label
            overflow: hidden;
            overflow-y: auto;
            border-radius: 6px;
            
            padding: 20px;
        
            @include breakpoint(medium) {
                max-width: 80%;
                max-width: 450px;
                padding: 40px;
            }
        
            @include breakpoint(huge) {
                width: 70%;
                max-width: 450px;
            }
        }
    }

    label {
    display:none;

        body:not(.cmsbackend) & {
            background: rgba(black, 0.8);
            display: flex;
            height: 100%;
            justify-content: flex-end;
            padding: rem($base-gap);
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: -1;
        
            i {
                cursor: pointer;
                display: block;
                height: rem(35px);
                width: rem(35px);
                position: relative;
                transition: background .3s;
        
                &:hover {
                    background: rgba(black, 0.8);
        
                    &:before, &:after {
                        background: white;
                    };
                }
        
                &:before, &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: rem(30px);
                    height: rem(2px);
                    background: #ddd;
                }
        
                &:before {
                    transform: translate(-50%,-50%) rotate(45deg);
                }
        
                &:after {
                    transform: translate(-50%,-50%) rotate(-45deg);
                }
            }
        }
    }
}

input[id^="popUpToggled"] {
    display: none;
    body:not(.cmsbackend) & {
        &:not(:checked) {
            
            + {
                
                .popUpContainer {
                    position: relative;
                    opacity: 0;
                    visibility: hidden;
                    z-index: -1000000;
                    transform:scale(0);
                    pointer-events: none;
                    width: 0;
                    height: 0;
                }
            }
        }
    }
}



/////COLLAPSER FAQ
//////////////////////////////
// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary;
$collapser-dark: $dark;

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        padding: $collapser-gaps-tb 78px $collapser-gaps-tb $collapser-gaps-lr;
        position: relative;
        cursor: pointer;

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $collapser-dark;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {
        border-radius: 6px;
        background: $secondary;
        color: $primary;
        font-weight: 800;
        &:before, &:after {
            background-color: white;

        }

}

// HEADER
//////////////////////////////

.header {
    background: $light;
    position: relative;
    width: 100%;

    @include breakpoint(large) {
        margin-bottom: 5rem;
    }
   
    body.unternehmen-standort &, body.anfahrt & {
        .map {
            @include breakpoint(large, max) {
                padding-top: 5rem;
            }
        }
    }

    .branding {
        display: none;
        padding: 20px;
        margin-top: -3rem;
        @include breakpoint(small) {
        margin-top: 0;
         }
        

        @include breakpoint(large) {
            float: left;
            padding: 0 !important;
            display: none;
        }

        img {
            @include breakpoint(tiny) {
                left: 50%;
                transform: translateX(-50%);
                position: relative;
            }

            @include breakpoint(small) {
                left: 22%;
            }


        }
    }

    .videoContainer {
        height: 12rem;
        overflow: hidden;
        position: relative;
        margin-bottom: 5rem;
        width: 100%;

        @include breakpoint(small) {
            display: block;
        }

        .video-wrap {

            // @include breakpoint(giant) {
            //     margin-top: -20rem;
            // }

            video {
                // height: 100%;
                // width: 100%;
                display: none;

                @include breakpoint(small) {
                    display: block;
                    
                     position: absolute;
                     top: 50%;
                     left: 50%;
                     min-width: 100%;
                     min-height: 100%;
                     width: auto;
                     height: auto;
                     transform: translateX(-50%) translateY(-50%);
                }
            }

            .claim {
                display: none;

            }
            img:not([class^='ewcms_']) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
                .cmsbackend & {
                    position:relative;
                    transform: translate(0, 0);
                    left: 0;
                }
            }
            .Headerbild {
                &.Desktop {
                    display: none;
                    @include breakpoint(small) {
                        display: block;
                    }
                }
                &.Mobil {
                    @include breakpoint(small) {
                        display: none;
                    }
                }
            }
        }
    }
}

body:not(.index) {
    .videoContainer {
        height: 0;
        display: none;

        @include breakpoint(small) {
            height: 15rem;

            margin-bottom: 3rem;
        }

        @include breakpoint(large) {
            height: 19rem;
        }


        @include breakpoint(giant) {
            height: 22rem;
        }

        .video-wrap {
            margin-top:-117px;
            @include breakpoint(large) {
                margin-top: -8rem;
            }

            @include breakpoint(giant) {
                margin-top: -15rem
            }

        }

    }

    .content {
       
        margin-top:50px;
             
             @include breakpoint(large) {
             margin-top: 0;
              }
             
       

        
    }

    .sortiment {
        height: auto;

    }

    .padtop {
        margin: 2rem;

        &.noSide {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .footer {
        margin-top: 2rem;

        @include breakpoint(large) {
            margin-top: 4rem;
        }

    }
}

main{
.sortiment {
  
        background: none !important;
    }
    .bgBienen{
        &.lazyLoaded{
            background-image: url("/images/panobilder/tiny-bienen.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(small) {
                background-image: url("/images/panobilder/small-bienen.jpg");
            }
            @include breakpoint(medium) {
                background-attachment: fixed;
                background-image: url("/images/panobilder/bienen.jpg");
                background-position: top;
            }
        }
    }
    .bgShop{
        &.lazyLoaded{
            background-image: url("/images/panobilder/tiny-Shop_2018.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(small) {
                background-image: url("/images/panobilder/small-Shop_2018.jpg");
            }
            @include breakpoint(medium) {
                background-attachment: fixed;
                background-image: url("/images/panobilder/Shop_2018.jpg");
                background-position: top;
            }
        }
    }
    .bgSchaden{
        &.lazyLoaded{
            background-image: url("/images/panobilder/tiny-dickmaulruessler-schaden.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(small) {
                background-image: url("/images/panobilder/small-dickmaulruessler-schaden.jpg");
            }
            @include breakpoint(medium) {
                background-attachment: fixed;
                background-image: url("/images/panobilder/dickmaulruessler-schaden.jpg");
                background-position: top;
            }
        }
    }
    .bgBuchs{
        &.lazyLoaded{
            background-image: url("/images/panobilder/tiny-buchsbaumzuensler.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(small) {
                background-image: url("/images/panobilder/small-buchsbaumzuensler.jpg");
            }
            @include breakpoint(medium) {
                background-attachment: fixed;
                background-image: url("/images/panobilder/buchsbaumzuensler.jpg");
                background-position: top;
            }
        }
    }
    .bgUnsershop{
        &.lazyLoaded{
            background-image: url("/images/panobilder/tiny-unser-shop.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(small) {
                background-image: url("/images/panobilder/small-unser-shop.jpg");
            }
            @include breakpoint(medium) {
                background-attachment: fixed;
                background-image: url("/images/panobilder/unser-shop.jpg");
                background-position: top;
            }
        }
    }
    .bgAnfahrt{
        &.lazyLoaded{
            background-image: url("/images/panobilder/tiny-anfahrt.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(small) {
                background-image: url("/images/panobilder/small-anfahrt.jpg");
            }
            @include breakpoint(medium) {
                background-attachment: fixed;
                background-image: url("/images/panobilder/anfahrt.jpg");
                background-position: top;
            }
        }
    }
    .bgZimmerpflanze{
        &.lazyLoaded{
            background-image: url("/images/panobilder/tiny-Zimmerpflanzen.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(small) {
                background-image: url("/images/panobilder/small-Zimmerpflanzen.jpg");
            }
            @include breakpoint(medium) {
                background-attachment: fixed;
                background-image: url("/images/panobilder/Zimmerpflanzen.jpg");
                background-position: top;
            }
        }
    }
    .bgGallmuecke{
        &.lazyLoaded{
            background-image: url("/images/panobilder/tiny-marienkaefer.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(small) {
                background-image: url("/images/panobilder/small-marienkaefer.jpg");
            }
            @include breakpoint(medium) {
                background-attachment: fixed;
                background-image: url("/images/panobilder/marienkaefer.jpg");
                background-position: center;
            }
        }
    }
    
    .preview {
        display: flex;
        overflow-y: auto;
        /* width */
        &::-webkit-scrollbar {
            width: 12px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 6px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #979b1e; 
            border-radius: 6px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #878853; 
        }
        .kreis {
            margin-right: 22px;
            left: 0%;
            .cmsbackend & {
                overflow: unset;
            }
        }
        .infos {
            @include breakpoint (giant, max) {
                display: flex;
            }
        }
    }
    

    //arrows//
        #arrowAnim {
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
            @include breakpoint(small) {
             display: none;
            }
        }

        .arrow {
            width: 1vw;
            border: 1.5vw solid;
            border-color: $primary transparent transparent $primary;
            transform: rotate(135deg);
        }


        .arrowSliding {
        position: absolute;
        -webkit-animation: slide 4s linear infinite; 
                animation: slide 4s linear infinite;
        }

        .delay1 {
        -webkit-animation-delay: 1s; 
            animation-delay: 1s;
        }
        .delay2 {
        -webkit-animation-delay: 2s; 
            animation-delay: 2s;
        }
        .delay3 {
        -webkit-animation-delay: 3s; 
            animation-delay: 3s;
        }

        @-webkit-keyframes slide {
            0% { opacity:0; transform: translateX(-15vw); }  
            20% { opacity:1; transform: translateX(-9vw); } 
            80% { opacity:1; transform: translateX(9vw); }  
            100% { opacity:0; transform: translateX(15vw); } 
        }
        @keyframes slide {
            0% { opacity:0; transform: translateX(-15vw); }  
            20% { opacity:1; transform: translateX(-9vw); } 
            80% { opacity:1; transform: translateX(9vw); }  
             100% { opacity:0; transform: translateX(15vw); } 

    }
    ///////
}

body.index {
    .videoContainer {
        height: 25rem;
        overflow: hidden;
        position: relative;
        margin-bottom: 0rem;
        
        @include breakpoint(small) {
            display: block;
            height: 21rem;
        }


        @include breakpoint(medium) {
            height: 27rem;
            margin-bottom: -18px;
              
        }

        @include breakpoint(large) {
            height: 32rem;
              margin-bottom: -99px;
        }
        @include breakpoint(giant) {
        height: 44rem;
         }
        
        @include breakpoint(huge) {
            height: 50rem;
        }

      


        .video-wrap {
            background: url(/images/tiny-headerbild.jpg) 0 0 no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            margin-top: 0;
            height: 25rem;
            display: block;
                @include breakpoint(tiny) {
                    background: url(/images/small-headerbild.jpg) 0 0 no-repeat;
                    background-size: cover;
                 }
                
            @include breakpoint(small) {
                
                background: none;
                height: 100%;
                margin-top: -113px;
            }
            @include breakpoint (large) {
                margin-top: -45px;
            }

            video {
               
                display: none !important;
                

                @include breakpoint(small) {
                    display: block !important;
                      position: absolute;
                     top: 50%;
                     left: 50%;
                     min-width: 100%;
                     min-height: 100%;
                     width: auto;
                     height: auto;
                     transform: translateX(-50%) translateY(-50%);

                }

                @include breakpoint(large) {
                    margin-top: 99px;
                }


            }

            .claim {
                align-items: center;
                display: block;
                text-shadow: 1px 1px 7px rgba(0, 0, 0, 1);
                color:$light;
                top: 13.4rem;
                transform: translateX(-50%);
                position: relative;
                text-align: center;
                z-index: 10;
                left:50%;
                @include breakpoint(small) {
                    top: 13.5rem;
                }
                @include breakpoint(medium) {
                    top: 19.5rem;
                }

                @include breakpoint(giant) {
                    top: 23.5rem;
                    left: 50%;
                    transform: translateX(-50%);
                    position: relative;
                    //margin-top: -160px; Als das Video drin war
                }


                @include breakpoint(huge) {
                    top: 26.5rem;
                }



                .padding {

                    padding: 34px 23px;

                    @include breakpoint(tiny) {
                        padding: 34px 43px;
                    }

                }

                .big {
                    font-size: 37px;
                    line-height: 34px;
                    font-weight: 700;
                    margin-bottom: 6px;
                    font-family: $display-font;
                    @include breakpoint(small) {
                    font-size: 47px;
                    line-height: 50px;
                     }
                    @include breakpoint(huge) {
                        font-size:68px;
                        margin-bottom: 33px;
                        line-height: 72px;
                     }
                    

                }
                p {
                    margin-bottom: 17px;
                    @include breakpoint (giant) {
                        margin-bottom: 0;
                    }
                }

                span {
                    font-size: 24px;
                    font-weight: 300;
                    @include breakpoint(small) {
                    font-size:42px;
                     }
                     @include breakpoint(huge) {
                         font-size: 62px;
                      }
                     
                }

                .btn {
                    font-weight: 300;
                    padding: 1.5rem 0.7rem;
                    width: 100.1%;
                    border-radius: 6px;

                    @include breakpoint(small) {
                        font-weight: 700;
                    }

                    @include breakpoint(medium) {
                        text-align: left;
                        padding-left: 50px;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        background: $secondary;
                        color: $dark;
                    }


                }

            }
            
        }
    }
}

body.cmsbackend {
    .claim {
        top: 40px !important;
        margin-bottom: 40px;
    }
    #x82730400 {
        margin-top: 30px !important;
        max-width: 90%;
    }
    .Headerbild.Mobil {
       display: block !important; 
    }
.teaser-icons, .blogCategoriesSlider {
        display: none !important;
    }
}   

body.index {
    .header{
        @include breakpoint(huge) {
        margin-bottom: 9rem;
         }
        
    }
}

.shop {
    @include breakpoint(giant) {
        margin: 7rem 0;
     }
    
}

// MAIN CONTENT
//////////////////////////////

main {
    display: block;

    ul:not(.blocks-gallery-grid),
    dl {
        @extend .styled-list;
    }

    .aktuelles-beitrag {
        text-align: left;

        .green {
            color: $green;
            font-weight: 500;
            margin-bottom: -15px;
        }

        h3 {
            color: $dark;
        }
    }

    .aktuell {
        .btn {
            margin-top: 3rem !important;

            @include breakpoint(large) {
                margin-top: 0 !important;
            }

        }
    }

    .google-maps {
        @extend .video-container;

        @include breakpoint(medium) {
            height: rem(400px);
        }
    }

    .karton-bg {
        width: 100%;
      
        @include breakpoint(large) {
         margin: 4rem 0;

         }
         @include breakpoint(giant) {
             margin: 7rem 0;
          }
         

        a {
            color: #fff;
            text-decoration: none;
        }
    }
    .news {
        .newsbox {
            background: #f8f8f86b;
            backdrop-filter: blur(4px);
            margin-bottom: 1rem;
            height: 95%;
            align-content: space-between;
            //border-radius: 6px;
            //border: 0.5px solid #b6b6b6;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 18px;
               
                @include breakpoint(medium) {
                    width: 98.6%!important;
                    margin-bottom: 0;
                    height: 100%;
                 }
                
            
            h2 {
                    padding-top: 20px;
                    font-size: 27px;
                    width: 100%;

                        @include breakpoint(medium) {
                        font-size:27px;
                         }
                         
                        
            }
            .hr {
                margin: 0;
            }
            .noMargin {
                margin: 0;
                &.categorieDes {
                    height: 111px;
                    line-height: 1.55556rem;
                    display: none;
                    @include breakpoint (tiny) {
                        height: 86px;
                        display: block;
                    }
                    @include breakpoint (tiny-2) {
                        height: 140px;
                    }
                    @include breakpoint (small) {
                        height: 118px;
                    }
                    @include breakpoint (medium) {
                        height: 93px;
                    }
                    @include breakpoint (medium-2) {
                        height: 139px;
                    }
                    @include breakpoint (large) {
                        height: 121px;
                    }
                    @include breakpoint (giant) {
                        height: 92px;
                    }
                }
            }
            .categorieInfo {
                display: flex;
                flex-direction: column;
                h2 {
                    margin-bottom: 7px;
                    margin-top: 20px;
                }
                div {
                    padding: 0 20px;
                }
                h2,p {
                    padding: 0 20px;
                }
            }
            .newPost {
                color: $primary;
                margin-top: 20px;
                font-size: 22px;
                text-transform: uppercase;
                font-weight: 100;
                letter-spacing: 1.2px;
                padding: 0px 20px 13px 20px;
            }
            .post {

                padding: 10px;
                background-color: white;
                margin:0 10px 10px 10px;
                
                
                .size {
                    
                    overflow:hidden;   
                    display:flex;
                    align-items: flex-start;
                    width: 100%;
                    height: 220px;
                    a{
                        min-width:100%;
                        min-height: 220px;
                        max-height: 220px;
                        display: flex;
                        justify-content: center;
                        align-items: stretch;
                    }
                    > img {
                        height: 100%;
                        object-position: center;
                    }
                    img {
                        width:100%;
                        object-fit:cover;
                    }
                    @include breakpoint(giant) {
                    height: 220px;
                    }
                    
                }
                
                .articlePost {
                    .h5 {
                        height: 50px;
                        margin-top: 15px;
                        @include breakpoint(tiny-2) {
                            height: 73px;
                        }
                        @include breakpoint(small) {
                            height: 50px;
                        }
                    }
                    div {
                        padding: 0px 0px;
                        display: flex;
                        justify-content: end;
                        .h5 {
                            padding: 0px 0px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .wiederverkauf {
        margin-bottom: 4rem; 
    }
    
    .headerleistung {
       
        width: 100%;
        text-align: center;
        margin: 5rem 0;
        margin-top: 1rem;

        @include breakpoint(medium) {
        margin:2rem 0;
         }
        
        @include breakpoint(large) {
            margin-left: -40px;
                width: 110%;
         }
        
        @include breakpoint(giant) {
            margin-top: -1.7rem;
         }
        
        

        a {
            color: #fff;
            text-decoration: none;
        }

             .kreis-top {
                    background: $primary;
                    text-align: center;
                    width: 200px;
                    height: 200px;
                    left: 250px;
                    padding-top: 4px;
                    border-radius: rem(200px);
                    transition: .6s;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    position: relative;
                    @include breakpoint(giant) {
                    
                    margin: 3rem 1rem 0 1rem;
                     }
                     @include breakpoint(huge) {
                     margin: 4rem 1rem 0 1rem;
                      }
                     
                    
                    img {
                        top: 38px;
                        left: 50%;
                        transform: translateX(-50%);
                        position: relative;
                    }

                    &:hover {
                        background: $green;
                    }

                    p {
                        top: 46px;
                        color:$light;
                        position: relative;
                    }
                }
    
    }
    .ausrichtung.giant-8 {
        
        @include breakpoint (giant) {
            margin: auto;
            position: relative;
            left: -35px;
        }
    }
    

        .kreis {
            background: $primary;
            text-align: center;
            width: 250px;
            height: 250px;
            left: 250px;
            margin: 2rem 0 0 0.9rem;
            padding-top: 4px;
            border-radius: rem(200px);
            transition: .6s;
            margin-bottom: 20px;
            margin-top: 10px;
            left: 50%;
            transform: translateX(-50%);
            position: relative;

            img {
                top: 48px;
                left: 50%;
                transform: translateX(-50%);
                position: relative;
                .cmsbackend & {
                    left: 0;
                }
            }

            &:hover {
                background: $green;
            }

            p {
                top: 65px;
                position: relative;

                &:after {
                    content: "\f105";
                    font-family: "FontAwesome";
                    display: inline-block;
                    margin-left: .5rem;
                    font-weight: 900;
                }
            }
        }

 

    .sortiment {
        &.lazyLoaded{
         background-image: url(../images/karton-bg.jpg);
         background-size: cover;
        }
        

       
        
        h3 {
            padding: 20px;
            color: $dark;

            @include breakpoint(large) {
                color: $dark;
            }

        }

        body.cmsbackend & {
          background:none;
        }

        a {
            text-decoration: none;
        }

        .kreis {
            &.lazyLoaded{
                background: url(../images/sortiment/nuetzlinge.jpg);
            }    
            text-align: center;
            width: 225px;
            display: flex;
            height: 225px;
            border-radius: 160px;
            transition: .6s;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            margin-bottom: 19px;
            left: 44%;
            transform: translateX(-50%);
            position: relative;
            .cmsbackend & {
                overflow: unset;
                display: flex;
                flex-direction: column;
                .ewcms_form, .ewcms_iframe {
                    left: 130% !important;
                } 
            }

            @include breakpoint(tiny) {
                width: 250px;
                height: 250px;
                left: 46%;
            }

            &:after {
                content: '';
                background: #2a2a2a00;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                opacity: 0.6;
                top: 0;
                transition: .6s;
                .cmsbackend & {
                    display: none;
                }
            }

            &:hover {
                &:after {
                    opacity: 0;
                }

                p {
                    display: none;
                }
            }

            &.zwei {
                &.lazyLoaded{
                    background: url(../images/sortiment/pflanzenschutzmittel.jpg);
                }

            }

            &.drei {
                &.lazyLoaded{
                    background: url(../images/sortiment/duenger.jpg);
                }

            }

            &.vier {
                &.lazyLoaded{
                    background: url(../images/sortiment/haushalt.jpg);
                }
            }

            &.fuenf {
                &.lazyLoaded{
                    background: url(../images/sortiment/bienengesundheit.jpg);
                    background-position: -40px -40px;
                }
            }

            &.sechs {
                &.lazyLoaded{
                    background: url(../images/sortiment/garten-teich.jpg);
                }
            }

            &.sieben {
                &.lazyLoaded{
                    background: url(../images/sortiment/buecher.jpg);
                }
            }

            &.acht {
                &.lazyLoaded{
                    background: url(../images/sortiment/pflanzen-samen.jpg);
                }
            }

            p {
                color: white;
                z-index: 10;
                position: relative;
                margin-bottom: 0;
                   margin-bottom: 130px;
                font-family: $display-font;

                &:after {
                    content: "\f105";
                    font-family: "FontAwesome";
                    display: inline-block;
                    margin-left: .5rem;
                    font-weight: 900;
                }
            }

        }
    }

    .teaserContainer {
        margin: 2rem 0 5rem;
        position: relative;

        .slick-slider {
            z-index: 99;
        }

        @include breakpoint(medium) {
            margin: 6rem 0 8rem;
        }

        @include breakpoint(huge) {
            margin: rem(235px) 0;
        }
    }

    .onlineshop {
        position: relative;
        @include breakpoint(large) {
            margin: 4rem 0;
         }
        
        @include breakpoint(huge) {
            margin:7rem 0;
        }
        .btn {
            width: 100%;
             margin-bottom: 3rem;
             position:relative;
             &.isOnScreen{
                &:before {
                    content: url(../images/icons/shop-icon.png);
                    font-size: .88889rem;            
                    display: inline-block;
                    position: relative;
                    padding-right: 10px;
                    -webkit-transition: 500ms all;
                    transition: 500ms all;
                    top: 6px !important;
                    position: relative;
                @include breakpoint(medium) {
                         left:-0.1rem;
                 }
                @include breakpoint(giant) {
                    // left: 3.4rem;
                 }
                
                }
            }
                &:after {
                   font-size: 14px;
                }
            @include breakpoint(medium) {
            margin-bottom: 0;
            font-size: 0.9rem;
             }
            
        }
    }

    .anfahrt {
        margin-bottom: 2rem;
        
        @include breakpoint(medium) {
        margin-bottom: 0;
         }
        
        @include breakpoint(large) {
            button {
                #submit {

                    padding: 0;
                }

                &:hover,
                &:active,
                &:focus {
                    background: $secondary;
                    color: $dark;
                }
            }
        }
        @include breakpoint(giant) {
            margin-top: 7rem;
             margin-bottom: 5rem;
         }
        

    }

    .kaefer {
        position: relative;
        &.lazyLoaded{
            &:before {
                content: '';
                right: 0;
                display: none;
                bottom: 0;
                background: url(../images/kaefer.png) 0 0 no-repeat;
                top: -90px;
                left: -110px;
                width: 220px;
                height: 205px;
                position: absolute;

                @include breakpoint(large) {
                    display: block;
                }

                @include breakpoint(huge) {
                    top: 10px;
                    left: -240px;

                }


            }
        }
    }

}

.fa-phone {
    transform: rotate(120deg);
    font-size: 14px;
}

.center {
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
}

.mail {
    position: relative;

    &:before {
        content: "\f0e0";
        font-family: "FontAwesome";
        display: inline-block;
        margin-right: .5rem;
        font-weight: 900;
        font-size: .88235rem;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}

.maps {
    margin-top: -50px;
    display:none;
    @include breakpoint(medium) {
        height: 81%;
        margin-top: 0;
        display:block;
    }

}

.mobile {
    display: block;

    @include breakpoint(medium) {
    display: none;
     }
    
}
.videobg{
            margin-bottom: 3rem;
                margin-top: -2rem;

                @include breakpoint(medium) {
                    margin-bottom: 4rem;
                         margin-top: 1rem;
                 }
                 @include breakpoint(giant) {
                       margin-bottom: 3rem;
                        margin-top: 6rem;
                  }
                 
                
       h2{
        margin-top: 2rem;
        @include breakpoint(medium) {
            margin-top: 0;
        }
        
       } 
      
        
}
.nuetzlinge {
        @include breakpoint(giant) {
                       margin-bottom: 6rem;
                        margin-top: 6rem;
                  }
}
    

// FOOTER
//////////////////////////////

.footer {
    display: block;
    background:$light;
    padding-top: 3.1rem;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    position: relative;

    @include breakpoint(large) {
        padding-top: 0;
        padding-bottom: 0;
    }
    &.isOnScreen{
        &:after {
            content: '';
            right: 0;
            bottom: 0;
            background: url(../images/buchsbaumzuensler.png) 0 0 no-repeat;
            top: -29px;
            right: 0;
            width: 26%;
            height: 20%;
            position: absolute;
            display: none;

            @include breakpoint(large) {
                display: block;
            }
        }
    }

    .headlineFooter {
        font-family: $display-font;
        font-weight: 700;
        font-size: 28px;
    }

    .kontaktbox {
        @include breakpoint(medium) {


            padding-top: 2rem;
        }


    }

    .sitemapBox {
        padding-top: 2rem;
        margin-top: 2rem;
        color: $light;
        background: $primary;
        padding-bottom: 2rem;

        a {
            color: $light;
        }

        @include breakpoint(large) {
            display: block;
            height: 380px;
            margin-top: 0rem;
            padding-top:2rem;
        }
        @include breakpoint(giant) {
            padding-left: 100px;
         }
        

    }

    .fa-map-marker-alt {
        margin-right: 0.5rem;
        font-size: 15px;
    }

    .sitemapBox {
        li {
            .sortiment {
                font-weight: 500 !important;
            }

            a {
                text-decoration: none;
                font-weight: 300;

                &:hover,
                &.active {
                    text-decoration: underline;
                }
            }

            &.hasSub {
                font-weight: 500;
            }

            .sub {
                li {

                    &:before {
                        content: "\f105";
                        font-family: $icon-font;
                        display: inline-block;
                        margin-right: .5rem;
                        font-weight: 900;
                        transition: all 300ms linear;
                    }
                }
            }
        }

        .navi-add-1,
        .headlineFooter {
            @include breakpoint(large) {

                left: 21px;
                position: relative;

            }
        }
    }

    .abstand {
        padding-bottom: 2rem;
        padding-top:0rem; 
   
        @include breakpoint(large) {
         padding-top: 3rem;
         } 
         .newsletter{
            display: none;
            @include breakpoint(large) {
            display: block;
             }
            
         }
    }

}

body.html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
    opacity: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}
body.index {
    &.lazyLoaded{
      background: url(../images/html-karton-bg.jpg);
      background-size: auto;
    }
    
    @include breakpoint(medium) {
    
   
    
     }
}
.sidebar-icons-stay {
    position: fixed;
    top: 8rem;
    right: 0;
    z-index: 80;
    -webkit-transition: 300ms all;
    transition: 300ms all;

    opacity: 1;
    visibility: visible;


    .button-slide {
        position: absolute;
        height: 56px;
        width: 56px;
        z-index: 80;
        right: 0;
        margin: 10px 0;
        padding: 7px 0 0 8px;
        background: $primary;
        overflow: hidden;
        -webkit-transition: width 300ms ease-in-out;
        transition: width 300ms ease-in-out;
        box-sizing: padding-box;
        box-sizing: border-box;
        border-radius: 6px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        a {
            display: flex;
            align-items: center;
        }
        img {
            width: rem(45px);
            height: rem(45px);
        }

    }
    &.visible {
            visibility: visible;
            opacity: 1;
    }
}
.sidebar-icons {
    position: fixed;
    top: 8rem;
    right: 0;
    z-index: 100;
    -webkit-transition: 300ms all;
    transition: 300ms all;

    opacity: 0;
    visibility: hidden;


    .button-slide {
        position: absolute;
        height: 56px;
        width: 56px;
        z-index: 100;
        right: 0;
        margin: 10px 0;
        padding: 7px 0 0 8px;
        background: $primary;
        overflow: hidden;
        -webkit-transition: width 300ms ease-in-out;
        transition: width 300ms ease-in-out;
        box-sizing: padding-box;
        box-sizing: border-box;
        border-radius: 6px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        a {
            display: flex;
            align-items: center;
        }
        img {
            width: rem(45px);
            height: rem(45px);
        }

    }

    .openinghours {
        margin-top: 4.3rem;
    }
    .mail-icon {
        margin-top: 8rem;
    }
    .map-icon {
        margin-top: 11.8rem;
    }
    .webshop {
        margin-top: 279px;
    }
    .youtube {
        margin-top: 347px;
    }
    .instagram{
        margin-top: 415px;
    }
    .facebook {
        margin-top: 480px;
    }


}
.visible {
    visibility: visible;
    opacity: 1;
}

.button-slide:hover span {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
}

.button-slide span {
    opacity: 0;
    font-size: 0.83333rem;
    font-weight: 400;
    color: $light;
    position: absolute;
    top: rem(16px);
    right: rem(20px);
    -webkit-transform: translateX(150%);
    transform: translateX(150%);
    -webkit-transition: opacity 300ms ease-in 300ms;
    transition: opacity 300ms ease-in 300ms;
}

.button-slide {
    &.search-icon {
        span {
            top: rem(7px) !important;
            line-height: 21px !important;
        }
        img {
            width: 35px;
            height: 35px;
            position: relative;
            top: 3px;
            left: 3px;
        }
        .suche {
            position: relative;
            top: -33px;
            left: 60px;
            [type="submit"] {
                width: 69px;
            }     
        }
    }
}
.button-slide {
    &.openinghours {
        span {
            top: rem(7px) !important;
            line-height: 21px !important;
        }
    }
}

.button-slide {
    &.map-icon {
        span {
            top: rem(7px) !important;
            line-height: 21px !important;
        }
    }
}

.button-slide:hover {
    width: 324px;
    background: $green;
}

.map-icon {
    &:hover {
        width: 214px;
    }
}

.mail-icon {
    &:hover {
        width: 254px;
    }
}
.teaser-icons {
    margin-bottom:2rem;
}

body.nojs {
    .teaser-icons {
        display: inline-block;

        .nojsfloat {
            float: left;
        }

        .kreis {
            img {
                left: 16%;
            }
        }
    }

    .sortiment {
        .teaser {
            left: 56%;
            transform: translateX(-50%);
            position: relative;
            display: inline-block;

            .nojsfloat {
                float: left;
            }
        }
    }
}

.suchfeld {
    display: flex;
    justify-content: flex-end;
    height: 0;
    .suche {
        margin-bottom: 0px;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-bottom: 2rem;
        z-index: 1;
        top: 98px;
        position: relative;
        
    
        @include breakpoint(small) {
            top: 66px;
            margin-top: 30px;
            bottom: 0;
            padding-bottom: 0;
            display: block;
        }
        .cmsbackend &{
            display: none;
        }
    
        @include breakpoint(large) {
            top: 100px;
        }
    }
}
.suche {

    #ewcms_search {
        height: 2rem;
        width: 180px;
        padding: 0 10px;
        background-color: $light;
        color: $dark;
        border: none;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        font-size: 15px;
        &:focus-visible {
            outline: 2px solid $primary;
            border-radius: 3px;
        }
        

        @include breakpoint(medium) {
            width: rem(180px);
        }
        
    }
    
    [type="submit"] {
        background: $primary;
        border: none;
        color: $light;
        height: 2rem;
        width: 80px;
        -webkit-appearance: none;
        transition: .6s;
        border-radius: 6px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        font-size: 15px;

        @include breakpoint(medium) {
            width: 105px;
            -webkit-appearance: none;
        }

        &:hover {
            background: $green;
        }
    }

}

body.cmsbackend {
    .page-bar {
    }

    .page-wrap {
        padding-top: 110px;
    }
}

// body.news {
//     .subheader {
//         background: url(/images/neuigkeiten.jpg) 0 0 no-repeat;
//     background-size: cover;
//     width: 100%;
//     margin-top: 0;
//     height: 12rem;
//     display: block;

//     @include breakpoint(large) {

//         height: 25rem;
//     }
//     h1 {
//         color: $light;
//     }

//     }
// }
// body.schaedlinge  {
//     .subheader {
//         background: url(/images/schaedlinge.jpg) 0 0 no-repeat;
//     background-size: cover;
//     width: 100%;
//     margin-top: 0;
//     height: 10rem;
//     display: block;
//     background-position:  0 -70px;
        
//         @include breakpoint(small) {
        
//     height: 12rem;
//          }
        
//     @include breakpoint(large) {
//         background-position: 0 -290px;
//         height: 25rem;
//     }

//     }

// }
// body.sortiment  {
//     .subheader {
//         background: url(/images/sortiment.jpg) 0 0 no-repeat;
//     background-size: cover;
//     width: 100%;
//     margin-top: 0;
//     height: 12rem;
//     display: block;
//     background-position:  0 -70px;

//     @include breakpoint(large) {
//         background-position: 0 -290px;
//         height: 25rem;
//     }

//     }
// }
// body.sortiment-buecher  {
//     .subheader {
//     background: url(/images/sortiment-buecher.jpg) 0 0 no-repeat;
//     background-size: cover;
//     width: 100%;
//     margin-top: 0;
//     height: 12rem;
//     display: block;
//     background-position:  0 -70px;

//     @include breakpoint(large) {
//         background-position: 0 -290px;
//         height: 25rem;
//     }

//     }
// }
// body.unternehmen-leitbild  {
//     .subheader {
//     background: url(/images/leitbild-header.jpeg) 0 0 no-repeat;
//     background-size: cover;
//     width: 100%;
//     margin-top: 0;
//     height: 12rem;
//     display: block;
//     background-position:  0 0px;

//     @include breakpoint(large) {
//         background-position: 0 90px;
//         height: 25rem;
//     }

//     }
// }

// body.unternehmen-team {
//         .subheader {
//     background: url(/images/subheader-team.jpg) 0 0 no-repeat;
//     background-size: cover;
//     width: 100%;
//     margin-top: 0;
//     height: 12rem;
//     display: block;
//     background-position:  0 0px;
// @include breakpoint(medium) {
// background-position: 0 -65px;
//  }

//     @include breakpoint(large) {
//         background-position: 0 40px;
//         height: 25rem;
//     }
//     @include breakpoint(giant) {
//     background-position: 0 0
//      }
    

//     }
// }

// body.kataloge-und-broschueren {
//         .subheader {
//     background: url(/images/download-header.jpg) 0 0 no-repeat;
//     background-size: cover;
//     width: 100%;
//     margin-top: 0;
//     height: 12rem;
//     display: block;
//     background-position:  0 0px;

//     @include breakpoint(large) {
        
//         height: 25rem;
//     }

//     }
// }
.subheader {
  
    width: 100%;
    display: block;
    max-height: 11rem;
    overflow: hidden;
    min-height: 15rem;
    min-width: 51rem;
    margin-top: rem(71px);
    @include breakpoint(medium) {
   
    margin-top: 4rem;
     }
     @include breakpoint(large) {
      max-height: 15rem;
      }
     @include breakpoint(giant) {
     max-height: 24rem;
      }
     
    .ewcms_img {
        img:not([class^='ewcms_']) {
            position: static;
            width: auto;
            height: auto;
            transform: none;
        }
    }
      
      img:not([class^='ewcms_']) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
      }

      > .row {
          z-index: 1;
      }

    
.subheadline{

    h1 {
        top: 3rem;
        position: absolute;
        color: $light;
        text-shadow: 2px 4px 8px rgba(0,0,0,0.36); 
        max-width: 15rem;
        word-break: break-all;
        hyphens: auto;

        @include breakpoint(tiny) {
        max-width: 27rem;  

        }
        @include breakpoint(small) {
        max-width: 35rem;
        top: 6rem;

        }

        @include breakpoint(medium) {
        max-width: 38rem;

        }
        @include breakpoint(large) {
        top: 6rem;
        }
  
 


    }
    span{
        font-weight: 100;
        display: block;
        font-size: smaller;
        color: $light;
    }
    
        img{
            .subimg{
             min-height: 15rem;
             min-width: 51rem; 
            }
       
        }
       
    
    
}
 


    .ewcms_edit_img {
        height: auto;
        width: auto;
    }

    .ewcms_edit_submit,
    .ewcms_edit_cancel {
        img {

            height: auto;
        }
    }
}
 .map {
        iframe {

            position: relative;

            @include breakpoint(large) {
                padding-top: 7.5rem;
                height: 25rem;
            }

        }
    }
     .schaedlinge-cms {
       

        h3 {
            padding: 20px;
            margin-top: 40px;
            color: $dark;

            @include breakpoint(tiny) {
                margin-top: 60px;
            }

            @include breakpoint(large) {
                margin-top: 92px;
                color: $green;
            }

            @include breakpoint(huge) {
                margin-top: 92px;
            }


        }
        @include breakpoint(large) {

            height: 38rem;
            background-position: -160px;
        }

        @include breakpoint(giant) {
            height: 51rem;
            background-position: -240px;
        }

        @include breakpoint(huge) {
            height: 52rem;
            background-position: 0;
        }

        a {
            text-decoration: none;
        }

        .kreis {
            text-align: center;
            width: 250px;
            display: flex;
            height: 250px;
            border-radius: 160px;
            transition: .6s;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            margin-bottom: 19px;
            left: 50%;
            transform: translateX(-50%);
            position: relative;

            &:after {
                content: '';
                background: $dark;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                opacity: 0.6;
                top: 0;
                transition: .6s;

            }

            &:hover {
                &:after {
                    opacity: 0;
                }

                p {
                    display: none;
                }
            }


            p {
                color: white;
                z-index: 10;
                position: relative;
                margin-bottom: 0;
                font-weight: 800;
                font-family: $display-font;

                &:after {
                    content: "\f105";
                    font-family: "FontAwesome";
                    display: inline-block;
                    margin-left: .5rem;
                    font-weight: 900;
                }
            }

        }
    }


 
    .panobild {
        height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom:3rem;

    @include breakpoint(tiny) {
        margin-bottom: 4rem;
     }
     
    
    @include breakpoint(large) {
          background-position: 50% 100%;
        height: 400px;
        background-attachment: fixed;
        transform: translateZ(300px) scale(1.1);
        margin-top: 5rem;
        margin-bottom: 9rem;
    
          body.ipad & {
            
          background-attachment: inherit;
          }

     }
}
.field-content {
    
    .panobild {
        height: 203px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom:3rem;
    @include breakpoint(tiny) {
        margin-bottom: 4rem;
     }
     @include breakpoint(huge) {
         margin-bottom: 7rem;
      }
     
    
    @include breakpoint(large) {
          background-position: 50% 100%;
        height: 350px;
        background-attachment: fixed;

          body.ipad & {
            
          background-attachment: inherit;
          }

     }

    }
    .panobild-1 {
     background-image: url(/images/panobilder/marienkaefer.jpg);
     background-position: 0px -11px;
         margin-top: 3rem;
         @include breakpoint(tiny) {
             margin-top: 4rem;
          }
          @include breakpoint(medium) {
              background-position: 0px -135px;
           }
           @include breakpoint(giant) {
               margin-top: 7rem;
            }
           
    }
    .panobild-2 {
     background-image: url(/images/panobilder/bienen.jpg);
     background-position: 0px -11px;
     @include breakpoint(tiny) {
         margin-top: 4rem;

      }
     
    }
     .panobild-3 {
     background-image: url(/images/panobilder/Shop_2018.jpg);
     background-position: 0px -11px;
     margin-bottom:0
    }
     .panobild-4 {
     background-image: url(/images/panobilder/dickmaulruessler-schaden.jpg);
     background-position: 0px -11px;
    }
     .panobild-5 {
     background-image: url(/images/panobilder/buchsbaumzuensler.jpg);
     margin-bottom:0;
     margin-top:3rem;
     @include breakpoint(tiny) {
    
         background-position: 0px  -91px;
      }
     
     @include breakpoint(small) {
     
         background-position: 0px -191px;
      }
     
     @include breakpoint(large) {
     
      background-position: 0px -11px;
      }
     

    }
     .panobild-6 {
     background-image: url(/images/panobilder/unser-shop.jpg);
     background-position: 0px -11px;
         margin-bottom: 0rem;
         @include breakpoint(tiny) {
             margin-bottom: 4rem;
          }
            @include breakpoint(huge) {
         margin-bottom: 7rem;
         margin-top:0px;
      }
     
         
    }
      .panobild-7 {
     background-image: url(/images/panobilder/anfahrt.jpg);
     background-position: 0px -11px;
         margin-top: 3rem;
         @include breakpoint(small) {
             background-position: 0px -271px;
          }
          @include breakpoint(huge) {
          margin-top:7rem;
           }
          
         
    }
      .panobild-8 {
     background-image: url(/images/panobilder/Zimmerpflanzen.jpg);
     background-position: 0px -11px;
     margin-top:0;
    }
  
}

.abstandtop {
    @include breakpoint(giant) {
    
        margin-top: 90px;
     }
    
}
.infos {
    width: 100%;
    .kreis {
        position: relative;
        background: $dark;
        cursor: pointer;
         body.cmsbackend & {
            background:none;
                margin-bottom: 9rem;
         }
        .bg-image {
            transform:translate(0);
            position: absolute;
            width: 100%;
            top: 0;
            height: 100%;
            left: 0;
            border-radius: 11.111rem;
            opacity: 0.5;
            transition:300ms linear;
            object-fit: cover;
            
            
             body.cmsbackend & {
                transform:none;
                position: relative;
                    top: 0;
                     width: 100%;
                     height:100%;
                  left: 0;
                  border-radius: 0;
                   opacity: 1;
              }
        }
        a {
            font-size: 21px;
            position: absolute;
            top: 42%;
              left: 50%;
             transform: translateX(-50%);
            opacity: 1;
              color: white;
                z-index: 10;
                font-family: $display-font;
                text-decoration:none;
                    
                     body.cmsbackend & {
                        color:$dark;
                        position: relative;
                        top:inherit;
                            width: auto;
                            left:0;
                            transform:none;

                     }

                &:after {
                    content: "\f105";
                    font-family: "FontAwesome";
                    margin-left: .5rem;
                    font-weight: 900;

                }
        }
        .ewcms_edit_link {
                margin-top: 40px!important;
        }
        &:hover {
            .bg-image {
                opacity: 1;
            }
            a {
                opacity: 0;
                body.cmsbackend & {
                   opacity: 1;
                    
                }
            }

        } 
          
    }
    .ewcms_loopend {
           margin-bottom: 108px!important;
    margin-top: 25rem;
    }
    
}

.dmr {
    display: none;
    @include breakpoint(medium) {
    display: block;

     }
    
}

.ewcms_odd {
    margin-bottom:3rem;
}

 .video {
        height: auto;
    width: 100%;
    margin-top:1rem;
    @include breakpoint(medium) {
    
    height:rem(205px);
     }
      @include breakpoint(giant) {
    
    height:rem(317px);
     }
    
    
}

.padtop {
    margin-top: 3rem;
    @include breakpoint(medium) {
    margin-top:4rem;
     }
    @include breakpoint(large) {
        margin-top: 10rem;
    }
    @include breakpoint(huge) {
    margin-top:0;
     }
    
    
}

.middle {

    margin-top:3rem;

    @include breakpoint(medium) {
    
        left: 44%;
    position: relative;
    transform: translateX(-50px);
     }
    
}
.middle2 {

    margin-top:2rem;
    @include breakpoint (large) {
        margin-top:1rem; 
    }

    display: flex;
    justify-content: center;
    @include breakpoint (full) {
        margin-top:3rem;
    } 
}
.headersub{
            
    background-size: cover;
    width: 100%;
    margin-top: 0;
    height: 12rem;
    display: block;
    background-position:  0 -70px;

    @include breakpoint(large) {
        background-position: 0 -290px;
        height: 25rem;
    }
}
.infos{
    padding-bottom: 1.555556rem;
}

////////////////////////YOUTUBE//////////////////////////////
.videoWrapper {
  position: relative;
  width: 100%;
  height: 0;
  background-color: #000;
  &43 {
    padding-top: 75%;
  } 
  &169 {
    padding-top: 56%;
  }
}
.videoIframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.videoPoster {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-size: cover;
  text-indent: -999em;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 800ms, height 0s;
  -moz-transition: opacity 800ms, height 0s;
  transition: opacity 800ms, height 0s;
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
    border: 5px solid white;
    border-radius: 100%;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    margin: -20px 0 0 -13px;
    border-left: 35px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  &:hover,
  &:focus {
    &:before {
      border-color: $primary;
    }
    &:after {
      border-left-color: $primary;
    }
  }
  .videoWrapperActive & {
    opacity: 0;
    height: 0;
    -webkit-transition-delay: 0s, 800ms;
    -moz-transition-delay: 0s, 800ms;
    transition-delay: 0s, 800ms;
  }
}


////////////////////////////////

.iexplore {
.resize {
    a {
        width: 250px;
        height: auto;
    }
    img {
        width: 250px;
        height: auto;

        @include breakpoint(medium) {
            padding: 30px;
        }
        @include breakpoint(giant) {
            padding: 0;
        }
    }
}
}

.Zebra_Pagination {
    overflow: visible;
    @include pie-clearfix;
}

.Zebra_Pagination ul {
    position: static;
}

.Zebra_Pagination li {
    right: 0;
}

.Zebra_Pagination a, .Zebra_Pagination span {
    color: $primary;
}

.Zebra_Pagination li a, .Zebra_Pagination li span {
    color: $primary;
    border-radius: 6px;

    &:hover {
        color: $primary;
    }
}

.Zebra_Pagination li.active a {
    background-color: $primary;
    border-color: $primary;
    pointer-events: none;
}

.Zebra_Pagination li.disabled a {
    cursor: not-allowed;
}

.post-card {
    border: 1px solid rgba($dark, 0.125);
    padding: rem($base-line-height) rem($base-gap);
    display: block;
    text-decoration: none;
    transition-property: border-color;
    transition-duration: 366ms;
    transition-timing-function: ease-in-out;
    color: inherit;
    width: 100%;
    background-color: $light;
    //border-radius: 6px;

    &:hover, &:active, &:focus {
        border-color: rgba($dark, 0.3);
        color: inherit;
    }

    .image {
        height: 0;
        padding-top: 100%;
        position: relative;

        > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.small-flex {
    @include breakpoint(small) {
        display: flex;
    }
}

.small-stretch {
    @include breakpoint(small) {
        align-items: stretch;
    }
}

.objectFit {
    object-fit: cover;
}

#sidebar {
    .sidebar__inner {
        background-color: $light;
    }

    ul {
        li {
            margin-bottom: 0;

            &:not(.active) {
                &:hover, &:active, &:focus {
                    a {
                        border-color: rgba($dark, 0.3);
                        color: rgba($dark, 0.666);
                    }

                    + li a {
                        border-top-color: rgba($dark, 0.3);
                    }
                }
            }

            &.active {
                color: $light;
            }
            &.active a{
                background-color: $primary;
            }

            &:last-child a {
                border-bottom-width: 1px;
            }
        }

        a {
            text-decoration: none;
            padding: rem($base-line-height / 2) rem($base-gap);
            color: inherit;
            display: block;
            border: 1px solid rgba($dark, 0.125);
            border-bottom-width: 0;
            transition-property: border-color, color;
            transition-duration: 366ms;
            transition-timing-function: ease-in-out;
            border-radius: 6px;
        }
    }
}

#blogSearchForm {
    display: flex;
    align-items: stretch;
    position: relative;
    overflow: visible;
   

    button, input {
        margin-bottom: 0;
        
    }

    input {
        flex-grow: 1;
        border-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    button {
        flex-shrink: 1;
        width: auto;
        background-color: rgba($dark, 0.05);
        border: 1px solid rgba($dark, 0.125);
        border-left-width: 0;
        border-radius: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:hover, &:active, &:focus {
            background-color: $light;
            border-color: rgba($dark, 0.3);
        }

        &:after {
            display: none;
        }
    }

    .results {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: $light;
    }
}

.hr.tilMedium {
    @include breakpoint(medium) {
        display: none;
    }
}

.wp-block-image, .wp-block-video, .wp-block-audio {
    @extend .margin-bottom;
}

.wp-block-columns {
    display: flex;
    flex-wrap: wrap;
}

.wp-block-gallery {
    @extend .margin-bottom;

    > ul {
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
            flex-direction: row;
            margin-left: rem(-$base-gap);
            margin-right: rem(-$base-gap);
        }

        > li {
            margin-bottom: rem($base-line-height / 1.5);

            @include breakpoint(medium) {
                display: flex;
                align-items: stretch;
                padding-left: rem($base-gap);
                padding-right: rem($base-gap);
                margin-bottom: 0;
            }
        }
    }

    &.columns-2 > ul > li {
        @include breakpoint(small) {
            max-width: 50%;
            flex-basis: 50%;
        }
    }

    &.columns-3 > ul > li {
        @include breakpoint(medium) {
            max-width: 33.3333333%;
            flex-basis: 33.3333333%;
        }
    }

    &.columns-4 > ul > li {
        @include breakpoint(medium) {
            max-width: 25%;
            flex-basis: 25%;
        }
    }

    figure {
        position: relative;
        display: flex;
        align-items: stretch;

        figcaption {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgb(28,28,28);
            background: -moz-linear-gradient(0deg, rgba(28,28,28,1) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(0deg, rgba(28,28,28,1) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(0deg, rgba(28,28,28,1) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c1c1c",endColorstr="#000000",GradientType=1);
            color: $light;
            text-align: center;
            font-size: 0.9em;
            line-height: 1.3;
            padding: rem($base-line-height / 2) rem($base-gap / 2);
        }

        img {
            object-fit: cover;
        }
    }
}

.wp-block-button.is-style-outline {
    
    @extend .margin-bottom;

    .wp-block-button__link {
        border: 2px solid;
        border-radius: 5px;

        &.has-white-background-color {
            background-color: $light;
        }
    }
}

figure.wp-block-image.alignfull.size-large.is-style-default {
    margin-bottom: rem($base-gap);

    @include breakpoint(giant) {
        margin-left: calc(#{rem($row-max-width / 2)} - 50vw - #{rem(9px)});
        width: calc(100% + (#{rem($row-max-width / 2)} + 50vw));
        height: rem(768px);
        overflow: hidden;
        position: relative;
    }

    figcaption {
        @include breakpoint(giant) {
            position: absolute;
            bottom: 0;
            background-color: $light;
            left: 0;
            right: 0;
            padding: rem($base-line-height / 1.5) rem($base-gap);
        }
    }

    img {
        @include breakpoint(giant) {
            width: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            max-width: none;
            height: 100%;
        }
    }
}

#blogPostTagList {
    margin-bottom: rem($base-line-height);

    b {
        margin-bottom: rem($base-gap);
        display: block;
    }

    ul {
        display: inline-flex;
        flex-wrap: wrap;
        margin-left: rem($base-gap / 1.5);

        &.unstyled-list {
            margin-left: 0;

            li {
                padding-left: 0;
                margin-bottom: 25px;
            }
        }

        
    
        .tag {
            border-radius: 0.75em;
            background-color: rgba($dark, 0.125);
            padding: 0.66em 0.5em;
            color: $dark;
            text-decoration: none;
    
            &:hover, &:active, &:focus {
                background-color: $primary;
                color: $light;
            }
        }
    
        li:not(:last-child) {
            margin-right: rem($base-gap / 2);
        }
    
    }
}
#x82667595 {
    top: -83px !important;
    position: relative;
}


.ratings {
    padding-bottom: rem(100px);
    h2 {
        text-align: center;
        padding-top: rem(50px);
    }
    body:not(.cmsbackend) & {
        .swiper {
            width: 100%;
    
            .swiper-button-prev,
            .swiper-button-next {
                height: rem(50px);
                width: rem(50px);
                // @extend .icon;
                // @extend .icon-thin-arrow;
                @extend %animated-transform;
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: rem(50px);
                top: unset;
                color: $dark;
    
    
                &:hover{
                    color: $primary;
                }
    
                &:before{
                    font-size: rem(27px);
    
                    @include breakpoint(huge) {
                        font-size: rem(50px);
                    }
                    
                }
    
                &:after{
                    content: unset;
                }
            }
    
            .swiper-button-prev {
                left: 50%;
                transform: translateX(-120%);
    
                &::before{
                    transform: rotate(180deg);
                }
            }
    
            .swiper-button-next {
                right: 50%;
                transform: translateX(120%);
            }
    
            .swiper-wrapper {    
                .swiper-slide {
                    display: flex;
                    flex-grow: 1;
                    height: auto;
                    background-color: $secondary;
    
                    .inner {
                        padding: rem($base-gap * 2);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        flex-grow: 1;
    
                        @include breakpoint(small) {
                            padding: rem(50px);
                        }
    
                        // @include breakpoint(large) {
                        //     padding: rem(70px);
                        // }
    
                        // @include breakpoint(giant) {
                        //     padding: rem(70px) rem(100px);
                        // }
                        
    
                        p, span {
                            max-width: rem(550px);
    
                            @include breakpoint(giant) {
                                max-width: rem(450px);
                            }
                        }
    
                        p {
                            margin-bottom: rem(10px);
                            font-size: rem(19.5px);

                            strong {
                                font-size: rem(24px);
                            }
                        }
    
                        span {
                            text-align: right;
                            font-weight: 800;
                            width: 100%;
                            font-size: rem(21px);
                        }
                    }
                }
    
                
            }
        }
    }

    body.cmsbackend & {
        .swiper {
            overflow: visible !important;
        }
        .swiper-wrapper{
            display: block;
            transform: translate3d(0px, 0px, 0px) !important;
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }

    
    .author-right {
        display:flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .rating-img {
        max-width: rem(100px);
    }

    .quote {
        max-width: rem(300px);
        max-height: rem(300px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        opacity: .015;
    }
}

body.schaedlinge {
    .schaedlingGallery {
        img {
        aspect-ratio: 1/1;
        object-fit: cover;
        }
    }
}

.img-slider-options {
    h2 {
        text-align: center;
    }
    body:not(.cmsbackend) & {
        .swiper {
            width: 100%;
            padding-bottom: rem(130px);
            padding-top: rem(70px - $base-line-height);
            
            @include breakpoint(large) {
                padding-top: rem(100px - $base-line-height);
            }
    
            .swiper-button-prev,
            .swiper-button-next {
                height: rem(50px);
                width: rem(50px);
                // @extend .icon;
                // @extend .icon-thin-arrow;
                @extend %animated-transform;
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: rem(50px);
                top: unset;
                color: $dark;
    
    
                &:hover{
                    color: $primary;
                }
    
                &:before{
                    font-size: rem(27px);
    
                    @include breakpoint(huge) {
                        font-size: rem(50px);
                    }
                    
                }
    
                &:after{
                    content: unset;
                }
            }
    
            .swiper-button-prev {
                left: 50%;
                transform: translateX(-120%);
    
                &::before{
                    transform: rotate(180deg);
                }
            }
    
            .swiper-button-next {
                right: 50%;
                transform: translateX(120%);
            }
    
            .swiper-wrapper {    
                .swiper-slide {
                    display: flex;
                    flex-grow: 1;
                    height: auto;
                    background-color: $secondary;
    
                    .inner {
                        padding: rem($base-gap * 2);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        flex-grow: 1;
    
                        @include breakpoint(small) {
                            padding: rem(50px);
                        }
    
                        @include breakpoint(large) {
                            padding: rem(70px);
                        }
    
                        @include breakpoint(giant) {
                            padding: rem(70px) rem(100px);
                        }
                        
    
                        p, span {
                            max-width: rem(550px);
    
                            @include breakpoint(giant) {
                                max-width: rem(450px);
                            }
                        }
    
                        p {
                            margin-bottom: rem(10px);
                        }
    
                        span {
                            text-align: right;
                            font-weight: 800;
                            width: 100%;
                        }
                    }
                }
    
                
            }
        }
    }

    body.cmsbackend & {
        &.swiper {
            overflow: visible !important;
        }
        .swiper {
            overflow: visible !important;
        }
        .swiper-wrapper{
            display: block;
            transform: translate3d(0px, 0px, 0px) !important;
        }
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
}

.text-center {
    text-align: center;
    margin: rem(55px) 0px 0px 0px;
}

.navBack {
    @include breakpoint($break) {
        display: none
    }
}

.kooperationen_middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        height: 264px;
    }

    // p {
    //     height: 84px;
    //     margin-bottom: unset;
    // }
}