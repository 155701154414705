@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */





/* Arrows */

.slick-prev,
.slick-next {
    display: none!important;
}


/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 50%;
    transform: translatex(-50%);
    width: 11.3rem;

    @include breakpoint(tiny) {
        bottom: -3.3rem;
     }
        

    
    @include breakpoint(medium-2) {
        display: none !important;
    }
   
    
    li {
        position: relative;
        display: inline-block;
        height: 0.7rem;
        width:  0.7rem;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        background: $dark;
            border-radius: 102px;



        &.slick-active {
            background: $green;
        }

       

        &:before {
            display: none;
        }
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}


.marqueeContainer   {
    .slick-slide .inner {
  margin:0 rem(15px);
  .first {
    margin-bottom: rem(30px);
  }
}
/* Slider */
.slick-slider {
   

    position: relative;

    display: block;

    -moz-box-sizing: border-box;
         box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    display: none;
   @include breakpoint(medium) {
        display: block;
        max-height: 20.9rem;
   }
   @include breakpoint(giant) {
        max-height: 100%;
    }
   
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
}
.leistungen  {
    .slick-slide {
        @include breakpoint(giant) {
        
         }
        
    }
}

.slick-slide {
    //width: 11%;
     @include breakpoint(giant) {
        //width: 25% !important;
     }
}
.headerleistung {
    
    .slick-track {
        @include breakpoint(large) {
        
         }
        
        @include breakpoint(giant) {
        
         }
        
    }
}

.blogCategoriesSlider {
    margin: 0 rem(-$base-gap);


    .slick-track {
        display: flex;
        align-items: stretch;
    }

    .slick-slide {
        padding: rem($base-gap) 0.5rem;
        min-height: 0;
        display: flex;
        align-items: stretch;
        @include breakpoint(large) {
            padding: rem($base-gap) rem($base-gap);
        }
    }
}