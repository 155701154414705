// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

$desktopHover: false; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem(10px);
	text-decoration: none;
	color: $navColor;
	@include breakpoint(large) {
		&:not(a){
			border-radius: 6px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		&.home, &.faq, &.downloads, &.blog, &.webshop, &.schaedlingsinformation, &.bestimmung {
			border-radius: 6px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		&.schaedlingsinformation{
			border-radius: 0px;
		}
	}
	@include breakpoint (giant) {
		padding: 1em rem($base-gap / 2);
		font-size: .9375rem;
	}
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $dark;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: 'angle-right';
	transform: rotateY(180deg);
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: "\f105";
	display: inline-block;
    margin-left: .5rem;
    font-weight: 900;
    // transition: all 300ms ease-in-out;
    @include breakpoint(large) {
    content: "\f107";
     }

}

$closeIcon: "\f410"; // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 90px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include breakpoint($break) {
						margin-right: 5.5px;

						.wiedrverkauf-kooperationen {
							span {
								display: block;
							}
						}
					}
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						word-break: break-all;

						@include sub {
							background: $navBg;

							&:before {
								@extend .h5;
								padding: 1em rem($base-gap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						    &:before {
                        content: "\f105";
                        font-family: $icon-font;
                        display: inline-block;
                        margin-left: .5rem;
                        font-weight: 900;
                        transition: all 300ms linear;

                        &:hover {
                            color: $secondary;
                        }
                    }
					}

					&:last-of-type {
						@include breakpoint(large, max) {
							margin-bottom: 5rem;
						}
					}
				}
				@include breakpoint(giant) {
					.sub {
						li {
							margin-right: 0px;
						}
					}
					.schaedlinge {
						.sub {
							max-height: 50vh;
							overflow-y: auto;
							overflow-x: hidden;

							&::-webkit-scrollbar {
								height: 10px;
								width: 10px;
								border-radius: 10px;
							}
							&::-webkit-scrollbar-thumb {
								background: $primary;
								border-radius: 10px;
							}
							&::-webkit-scrollbar-track {
								background: rgba(255, 255, 255, 0);
							}
						}
					}
				}
			}
		}
	}
}
.page-bar {

    z-index: 40;
    width: 100%;
    background: white;

    .branding {
        display: block;

        @include breakpoint(large) {
            margin-top: rem(11px);
            display: none;

        }
    }

    .branding-header {
        display: none;

        @include breakpoint(large) {
            display: block;
            margin-top: -.5rem;
        }

    }

    @include breakpoint($break) {
        position: fixed;
        box-shadow: 0 0.44444rem 0.55556rem rgba(0, 0, 0, 0.1);
		.row {
			padding-top: 20px;
		}
    }
}
// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		position: relative !important;
		nav {
			  .mobile-nav {
        			width: 100%;
        			display: block;
       				text-align: center;

      			 @include breakpoint(medium) {
      			    display:none!important;
      			 }
    			}
			ul {
				margin-top: 1.5rem;

				&.navi {
					li {
                        .webshop{
                            background-color: $primary;
                            color: $light;
							border-bottom-left-radius: 0;
    						border-bottom-right-radius: 0;
                        }

						&.schaedlingsinformation {
							ul {
								li {
									a {
										padding: rem($base-gap / 1.5) rem($base-gap);
									}
								}
							}
						}
						&.hasSub {


							.sub {
                                .nuetzlinge{
                                    margin: 0;
                                }
							}
						}
						&.nav-back {
							&:before{
								margin-left: 0rem;
							}

						}
					}
				}
			}
		}
	}
}



    .footernav{
width: 100%;
background: $primary;

.navi-add {

    color: $light;
    text-align: center;

    @include breakpoint (medium) {
       display: flex;
        justify-content: space-between;

    }




    li {
        width: 100%;

        a {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            color: $light;
            display: block;
            font-weight: 300;
            padding: 1rem 0;
            text-decoration: none;
            font-size: .9375rem;

            @include breakpoint (large) {
                background: none;
                border: none;
            }
        }

        &.active,
        &:hover {
            a {
                background: $secondary;
                color: $dark;
                -webkit-transition: all 300ms linear;
                transition: all 300ms linear;
            }

        }

        @include breakpoint(large) {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: auto;
        }
    }
}
}
// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($base-gap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}

.navi-main li:nth-of-type(1) a {
	position: relative;
	@include breakpoint($break) {
		border-top-left-radius: rem(6px);
		border-top-right-radius: rem(6px);
	}
}
.navi-main li:nth-of-type(1) a:before {
	content:"";
	height: 18px;
	width: 21px;
	display: inline-block;
	background-image: url(../../../images/icons/haushalt-icon-black.png);
	background-size: 18px 18px;
	background-position: center center;
	transition: all .3s ease-in-out;
}

.navi-main li:nth-of-type(1) a:hover:before {
	background-image: url(../../../images/icons/haushalt-icon.png);
}

.navi-main li:nth-of-type(1) a.active:before {
	background-image: url(../../../images/icons/haushalt-icon.png);
}

@include breakpoint($break) {
	.navi.navi-main .sub li a {
		padding: .5rem !important;
	}
}